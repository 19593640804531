import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import { useGetList, useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import PageSelector from './PageSelector'
import { Bar, BarChart, CartesianGrid, Cell, LabelList, Legend, Pie, PieChart, Tooltip, XAxis, YAxis } from 'recharts'

const initialAvgTip = {
    result: 0,
    sum: 0,
    number: 0,
}

const PaymentStatusDash = ({ transaction, gateway }) => {
    const t = useTranslate()

    // Transaction Data
    const [time, setTime] = useState('3')
    const [selectedTransactionData, setSelectedTransactionData] = useState(transaction[time])
    const [selectedGatewayData, setSelectedGatewayData] = useState(gateway[time])

    // Chart Data -> may be better without state
    const [pieChartTransactionData, setPieChartTransactionData] = useState([
        { name: t('spgo.paymentMethodName.credit_card_non3d'), successTransaction: 0, successGateway: 0, color: '#DFB750' },
        { name: t('spgo.paymentMethodName.apple_pay'), successTransaction: 0, successGateway: 0, color: '#000000' },
        { name: t('spgo.paymentMethodName.google_pay'), successTransaction: 0, successGateway: 0, color: '#EA4335' },
        { name: t('spgo.paymentMethodName.pay_pal'), successTransaction: 0, successGateway: 0, color: '#013087' },
        { name: t('spgo.paymentMethodName.sofort'), successTransaction: 0, successGateway: 0, color: '#EB6F93' },
        { name: t('spgo.paymentMethodName.credit_card'), successTransaction: 0, successGateway: 0, color: '#FF7500' },
        { name: t('spgo.paymentMethodName.alipay'), successTransaction: 0, successGateway: 0, color: '#01A0E9' },
        { name: t('spgo.paymentMethodName.wechat'), successTransaction: 0, successGateway: 0, color: '#19C924' },
        { name: t('spgo.paymentMethodName.paydirekt'), successTransaction: 0, successGateway: 0, color: '#0D933A' },
        { name: t('spgo.paymentMethodName.voucher_card'), successTransaction: 0, successGateway: 0, color: '#0D933A' },
    ])
    const [barChartTransactionStatusData, setBarChartTransactionStatusData] = useState([
        {
            name: t('spgo.paymentMethodName.credit_card_non3d'),
            successTransaction: 0,
            successGateway: 0,
            initializedTransaction: 0,
            initializedGateway: 0,
            cancelledTransaction: 0,
            cancelledGateway: 0,
            failedTransaction: 0,
            failedGateway: 0,
        },
        {
            name: t('spgo.paymentMethodName.apple_pay'),
            successTransaction: 0,
            successGateway: 0,
            initializedTransaction: 0,
            initializedGateway: 0,
            cancelledTransaction: 0,
            cancelledGateway: 0,
            failedTransaction: 0,
            failedGateway: 0,
        },
        {
            name: t('spgo.paymentMethodName.google_pay'),
            successTransaction: 0,
            successGateway: 0,
            initializedTransaction: 0,
            initializedGateway: 0,
            cancelledTransaction: 0,
            cancelledGateway: 0,
            failedTransaction: 0,
            failedGateway: 0,
        },
        {
            name: t('spgo.paymentMethodName.pay_pal'),
            successTransaction: 0,
            successGateway: 0,
            initializedTransaction: 0,
            initializedGateway: 0,
            cancelledTransaction: 0,
            cancelledGateway: 0,
            failedTransaction: 0,
            failedGateway: 0,
        },
        {
            name: t('spgo.paymentMethodName.sofort'),
            successTransaction: 0,
            successGateway: 0,
            initializedTransaction: 0,
            initializedGateway: 0,
            cancelledTransaction: 0,
            cancelledGateway: 0,
            failedTransaction: 0,
            failedGateway: 0,
        },
        {
            name: t('spgo.paymentMethodName.credit_card'),
            successTransaction: 0,
            successGateway: 0,
            initializedTransaction: 0,
            initializedGateway: 0,
            cancelledTransaction: 0,
            cancelledGateway: 0,
            failedTransaction: 0,
            failedGateway: 0,
        },
        {
            name: t('spgo.paymentMethodName.alipay'),
            successTransaction: 0,
            successGateway: 0,
            initializedTransaction: 0,
            initializedGateway: 0,
            cancelledTransaction: 0,
            cancelledGateway: 0,
            failedTransaction: 0,
            failedGateway: 0,
        },
        {
            name: t('spgo.paymentMethodName.wechat'),
            successTransaction: 0,
            successGateway: 0,
            initializedTransaction: 0,
            initializedGateway: 0,
            cancelledTransaction: 0,
            cancelledGateway: 0,
            failedTransaction: 0,
            failedGateway: 0,
        },
        {
            name: t('spgo.paymentMethodName.paydirekt'),
            successTransaction: 0,
            successGateway: 0,
            initializedTransaction: 0,
            initializedGateway: 0,
            cancelledTransaction: 0,
            cancelledGateway: 0,
            failedTransaction: 0,
            failedGateway: 0,
        },
        {
            name: t('spgo.paymentMethodName.voucher_card'),
            successTransaction: 0,
            successGateway: 0,
            initializedTransaction: 0,
            initializedGateway: 0,
            cancelledTransaction: 0,
            cancelledGateway: 0,
            failedTransaction: 0,
            failedGateway: 0,
        },
    ])
    const [barChartTransactionTypeData, setBarChartTransactionTypeData] = useState([
        { valueTransaction: 0, valueGateway: 0, tipTransaction: 0, tipGateway: 0, name: t('spgo.paymentMethodName.credit_card_non3d'), color: '#DFB750' },
        { valueTransaction: 0, valueGateway: 0, tipTransaction: 0, tipGateway: 0, name: t('spgo.paymentMethodName.apple_pay'), color: '#000000' },
        { valueTransaction: 0, valueGateway: 0, tipTransaction: 0, tipGateway: 0, name: t('spgo.paymentMethodName.google_pay'), color: '#EA4335' },
        { valueTransaction: 0, valueGateway: 0, tipTransaction: 0, tipGateway: 0, name: t('spgo.paymentMethodName.pay_pal'), color: '#013087' },
        { valueTransaction: 0, valueGateway: 0, tipTransaction: 0, tipGateway: 0, name: t('spgo.paymentMethodName.sofort'), color: '#EB6F93' },
        { valueTransaction: 0, valueGateway: 0, tipTransaction: 0, tipGateway: 0, name: t('spgo.paymentMethodName.credit_card'), color: '#FF7500' },
        { valueTransaction: 0, valueGateway: 0, tipTransaction: 0, tipGateway: 0, name: t('spgo.paymentMethodName.alipay'), color: '#01A0E9' },
        { valueTransaction: 0, valueGateway: 0, tipTransaction: 0, tipGateway: 0, name: t('spgo.paymentMethodName.wechat'), color: '#19C924' },
        { valueTransaction: 0, valueGateway: 0, tipTransaction: 0, tipGateway: 0, name: t('spgo.paymentMethodName.paydirekt'), color: '#0D933A' },
        { valueTransaction: 0, valueGateway: 0, tipTransaction: 0, tipGateway: 0, name: t('spgo.paymentMethodName.voucher_card'), color: '#0D933A' },
    ])
    const [barChartTipData, setBarChartTipData] = useState([
        { avgTip: 0, success: 0, name: t('spgo.paymentMethodName.credit_card_non3d'), color: '#DFB750' },
        { avgTip: 0, success: 0, name: t('spgo.paymentMethodName.apple_pay'), color: '#000000' },
        { avgTip: 0, success: 0, name: t('spgo.paymentMethodName.google_pay'), color: '#EA4335' },
        { avgTip: 0, success: 0, name: t('spgo.paymentMethodName.pay_pal'), color: '#013087' },
        { avgTip: 0, success: 0, name: t('spgo.paymentMethodName.sofort'), color: '#EB6F93' },
        { avgTip: 0, success: 0, name: t('spgo.paymentMethodName.credit_card'), color: '#FF7500' },
        { avgTip: 0, success: 0, name: t('spgo.paymentMethodName.alipay'), color: '#01A0E9' },
        { avgTip: 0, success: 0, name: t('spgo.paymentMethodName.wechat'), color: '#19C924' },
        { avgTip: 0, success: 0, name: t('spgo.paymentMethodName.paydirekt'), color: '#0D933A' },
        { avgTip: 0, success: 0, name: t('spgo.paymentMethodName.voucher_card'), color: '#0D933A' },
    ])
    const [avgTip, setAvgTip] = useState(initialAvgTip)

    const calculateDataTransaction = (data) => {
        // Calculate entries from raw data
        data.forEach(entry => {
            if (entry.paymentMethodId) {
                if (entry.status === 'success') {
                    pieChartTransactionData[entry.paymentMethodId - 1].successTransaction++
                    barChartTransactionStatusData[entry.paymentMethodId - 1].successTransaction++
                    barChartTipData[entry.paymentMethodId - 1].success++
                    barChartTransactionTypeData[entry.paymentMethodId - 1].valueTransaction += entry.enteredAmountCents
                    barChartTransactionTypeData[entry.paymentMethodId - 1].tipTransaction += entry.tipsAmountCents
                    barChartTipData[entry.paymentMethodId - 1].avgTip += entry.tipsPercentage
                } else if (entry.status === 'cancelled') {
                    barChartTransactionStatusData[entry.paymentMethodId - 1].cancelledTransaction++
                } else if (entry.status === 'initialized') {
                    barChartTransactionStatusData[entry.paymentMethodId - 1].initializedTransaction++
                } else {
                    barChartTransactionStatusData[entry.paymentMethodId - 1].failedTransaction++
                }
            }
        })

        // Round to 2 decimals
        barChartTransactionTypeData.forEach(entry => {
            entry.valueTransaction = entry.valueTransaction / 100
            entry.tipTransaction = entry.tipTransaction / 100
        })
        barChartTipData.forEach(entry => {
            entry.avgTip = (entry.success !== 0 && entry.avgTip !== 0) ? (Math.round((entry.avgTip / entry.success) * 100) / 100) : 0
        })

        // Save to state
        setPieChartTransactionData(pieChartTransactionData)
        setBarChartTransactionStatusData(barChartTransactionStatusData)
        setBarChartTransactionTypeData(barChartTransactionTypeData)
        setBarChartTipData(barChartTipData)
    }
    const calculateDataGateway = (data) => {
        data.forEach(entry => {
            if (entry.status === 'success') {
                pieChartTransactionData[entry.paymentMethodId - 1].successGateway++
                barChartTransactionStatusData[entry.paymentMethodId - 1].successGateway++
                barChartTransactionTypeData[entry.paymentMethodId - 1].valueGateway += entry.enteredAmountCents
                barChartTransactionTypeData[entry.paymentMethodId - 1].tipGateway += entry.tipsAmountCents
            } else if (entry.status === 'cancelled')
                barChartTransactionStatusData[entry.paymentMethodId - 1].cancelledGateway++
            else if (entry.status === 'initialized')
                barChartTransactionStatusData[entry.paymentMethodId - 1].initializedGateway++
            else
                barChartTransactionStatusData[entry.paymentMethodId - 1].failedGateway++
        })

        // Round to 2 decimals
        barChartTransactionTypeData.forEach(entry => {
            entry.valueGateway = entry.valueGateway / 100
            entry.tipGateway = entry.tipGateway / 100
        })
        setPieChartTransactionData(pieChartTransactionData)
        setBarChartTransactionStatusData(barChartTransactionStatusData)
        setBarChartTransactionTypeData(barChartTransactionTypeData)
    }

    useEffect(() => {
        setSelectedGatewayData(gateway[time])
        setSelectedTransactionData(transaction[time])
    }, [time, transaction, gateway])
    useEffect(() => {
        calculateDataTransaction(selectedTransactionData)
        calculateDataGateway(selectedGatewayData)
    }, [selectedTransactionData, selectedGatewayData])
    useEffect(() => {
        const newAvgTip = { ...initialAvgTip }
        selectedTransactionData.filter(val => val.status === 'success').forEach(entry => {
            newAvgTip.sum += entry.tipsPercentage
            newAvgTip.number++
        })
        newAvgTip.result = (newAvgTip.sum !== 0 && newAvgTip.number !== 0) ? (Math.round(newAvgTip.sum / newAvgTip.number) * 100) / 100 : 0
        setAvgTip(newAvgTip)
    }, [selectedTransactionData])

    const { data, loading, error } = useGetList('clients', { page: 1, perPage: -1 }, { field: 'id', order: 'ASC' }, {})
    if (loading) return <p>{t('spgo.load')}</p>
    if (error) return <p>{t('spgo.error')}</p>
    if (!data) return null

    const clients = Object.values(data)
    const timesObject = [
        { name: t('spgo.dashboard.graphDash.pieUser.single'), value: clients.filter(entry => entry.transactionCount === 1).length, color: 'MediumPurple' },
        { name: t('spgo.dashboard.graphDash.pieUser.multiple'), value: clients.filter(entry => entry.transactionCount > 1).length, color: 'OliveDrab' },
    ]

    const renderCustomizedLabel1 = (props) => {
        const { cx, cy, midAngle, innerRadius, outerRadius, name, percent, color } = props
        const RADIAN = Math.PI / 180
        const radius = 20 + innerRadius + (outerRadius - innerRadius)
        const x = cx + radius * Math.cos(-midAngle * RADIAN)
        const y = cy + radius * Math.sin(-midAngle * RADIAN)
        return (
            <text x={x} y={y} fill={color} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {name} | {Math.round(percent * 100)}%
            </text>
        )
    }
    const renderColorfulLegend = (value, entry) => <span style={{ color: entry.color, fontSize: '12px' }}>{value}</span>
    const handleTimeChange = (event) => setTime(event.target.value)

    const scope = t(`spgo.dashboard.dropdown.${['day', 'week', 'month', 'total'][time]}`)
    return (
        <Grid container spacing={3}>
            <PageSelector time={time} text={t('spgo.dashboard.titles.payData')} handler={handleTimeChange}/>
            { /* Pie chart with successful transactions by payment type */}
            <Grid item xs={12} md={6} lg={6}>
                <PieChart width={515} height={220} style={{ margin: 'auto' }}>
                    <Pie dataKey="successGateway" data={pieChartTransactionData.filter(entry => entry.successGateway !== 0)} fill="#8884d8" cx='50%' cy='110px' innerRadius={10}
                         outerRadius={30}
                         paddingAngle={2}>
                        {
                            // key has to be unique, therefore the 0/1 + index
                            pieChartTransactionData.filter(entry => entry.successGateway !== 0).map((entry, index) => <Cell key={'0' + index} fill={entry.color}/>)
                        }
                    </Pie>
                    <Pie dataKey="successTransaction" data={pieChartTransactionData.filter(entry => entry.successTransaction !== 0)} fill="#8884d8" cx='50%' cy='110px'
                         innerRadius={40} outerRadius={60}
                         paddingAngle={2} label={renderCustomizedLabel1}>
                        {
                            // key has to be unique, therefore the 0/1 + index
                            pieChartTransactionData.filter(entry => entry.successTransaction !== 0).map((entry, index) => <Cell key={'0' + index} fill={entry.color}/>)
                        }
                    </Pie>
                    <Tooltip/>
                </PieChart>
                <Box marginBottom={'20px'} textAlign={'center'} fontSize={'14px'} fontWeight={'bold'} color={'rgba(0, 0, 0, 0.54)'} style={{ whiteSpace: 'pre-line' }}>
                    {
                        `${t('spgo.dashboard.graphDash.piePayment')} (${scope})\n${t('spgo.dashboard.graphDash.outerInnerMessage')}`
                    }
                </Box>
            </Grid>
            { /* Pie chart with one time and recurring users */}
            <Grid item xs={12} md={6} lg={6}>
                <PieChart width={400} height={220} style={{ margin: 'auto' }}>
                    <Pie dataKey="value" data={timesObject} fill="#8884d8" cx='50%' cy='105px' innerRadius={40} outerRadius={60} paddingAngle={5}
                         label={renderCustomizedLabel1}>
                        {
                            // key has to be unique, therefore the 0/1 + index
                            timesObject.map((entry, index) => <Cell key={'0' + index} fill={entry.color}/>)
                        }
                    </Pie>
                    <Tooltip/>
                </PieChart>
                <Box marginBottom={'20px'} textAlign={'center'} fontSize={'14px'} fontWeight={'bold'} color={'rgba(0, 0, 0, 0.54)'}>
                    {t('spgo.dashboard.graphDash.pieUser.title')}
                </Box>
            </Grid>
            { /* Stacked bar chart with transactions by payment type and status */}
            <Grid item xs={12} md={6} lg={6}>
                <BarChart width={350} height={250} data={barChartTransactionStatusData.filter(entry => entry.successTransaction || entry.successGateway)} barCategoryGap={10}
                          maxBarSize={20}
                          style={{ fontSize: '10px', margin: 'auto' }} layout="vertical">
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis type="number" domain={[0, dataMax => dataMax + 100]} tickFormatter={tick => tick.toLocaleString('de', { style: 'decimal' })}/>
                    <YAxis type="category" dataKey="name"/>
                    <Tooltip formatter={(value) => value.toLocaleString('de', { style: 'decimal' })}/>
                    <Legend payload={[
                        { value: t('spgo.dashboard.graphDash.barStatus.init'), type: 'square', color: '#5bc0de' },
                        { value: t('spgo.dashboard.graphDash.barStatus.cancel'), type: 'square', color: '#f0ad4e' },
                        { value: t('spgo.dashboard.graphDash.barStatus.failed'), type: 'square', color: '#d9534f' },
                        { value: t('spgo.dashboard.graphDash.barStatus.success'), type: 'square', color: '#5cb85c' },
                    ]}
                            formatter={renderColorfulLegend}
                    />
                    <Bar dataKey="initializedTransaction" stackId="a" fill="#5bc0de" name={t('spgo.dashboard.graphDash.barStatus.init')}/>
                    <Bar dataKey="cancelledTransaction" stackId="a" fill="#f0ad4e" name={t('spgo.dashboard.graphDash.barStatus.cancel')}/>
                    <Bar dataKey="failedTransaction" stackId="a" fill="#d9534f" name={t('spgo.dashboard.graphDash.barStatus.failed')}/>
                    <Bar dataKey="successTransaction" stackId="a" fill="#5cb85c" label={{ position: 'right' }} name={t('spgo.dashboard.graphDash.barStatus.success')}/>
                    <Bar dataKey="initializedGateway" stackId="b" fill="#5bc0de" name={t('spgo.dashboard.graphDash.barStatus.init')}/>
                    <Bar dataKey="cancelledGateway" stackId="b" fill="#f0ad4e" name={t('spgo.dashboard.graphDash.barStatus.cancel')}/>
                    <Bar dataKey="failedGateway" stackId="b" fill="#d9534f" name={t('spgo.dashboard.graphDash.barStatus.failed')}/>
                    <Bar dataKey="successGateway" stackId="b" fill="#5cb85c" label={{ position: 'right' }} name={t('spgo.dashboard.graphDash.barStatus.success')}/>
                </BarChart>
                <Box marginBottom={'20px'} textAlign={'center'} fontSize={'14px'} fontWeight={'bold'} color={'rgba(0, 0, 0, 0.54)'} style={{ whiteSpace: 'pre-line' }}>
                    {
                        `${t('spgo.dashboard.graphDash.barStatus.title')} (${scope})\n${t('spgo.dashboard.graphDash.upperLowerMessage')}`
                    }
                </Box>
            </Grid>
            { /* Stacked bar chart with amounts and tips by payment type */}
            <Grid item xs={12} md={6} lg={6}>
                <BarChart width={350} height={250} data={barChartTransactionTypeData.filter(entry => entry.valueTransaction || entry.valueGateway)} barCategoryGap={15}
                          maxBarSize={40}
                          style={{ fontSize: '10px', margin: 'auto' }} layout="vertical">
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis type="number" domain={[0, dataMax => (Math.round(dataMax) + 1300)]} tickFormatter={tick => tick.toLocaleString('de', { style: 'decimal' })}/>
                    <YAxis type="category" dataKey="name"/>
                    <Tooltip formatter={(value) => value.toLocaleString('de', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}/>
                    <Legend payload={
                        [
                            { value: t('spgo.dashboard.graphDash.barVolume.amount'), type: 'square', color: '#8884d8' },
                            { value: t('spgo.dashboard.graphDash.barVolume.tip'), type: 'square', color: '#82ca9d' },
                        ]
                    }
                            formatter={renderColorfulLegend}/>
                    <Bar name={t('spgo.dashboard.graphDash.barVolume.amount')} dataKey="valueTransaction" stackId="a" fill="#8884d8">
                        {
                            barChartTransactionTypeData.filter(entry => entry.valueTransaction || entry.valueGateway).map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color}/>
                            ))
                        }
                    </Bar>
                    <Bar name={t('spgo.dashboard.graphDash.barVolume.tip')} dataKey="tipTransaction" stackId="a" fill="#82ca9d">
                        <LabelList position='right' formatter={(value) => value.toLocaleString('de', {
                            style: 'currency',
                            currency: 'EUR',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}/>
                    </Bar>
                    <Bar name={t('spgo.dashboard.graphDash.barVolume.amount')} dataKey="valueGateway" stackId="b" fill="#8884d8">
                        {
                            barChartTransactionTypeData.filter(entry => entry.valueTransaction || entry.valueGateway).map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color}/>
                            ))
                        }
                    </Bar>
                    <Bar name={t('spgo.dashboard.graphDash.barVolume.tip')} dataKey="tipGateway" stackId="b" fill="#82ca9d">
                        <LabelList position='right' formatter={(value) => value.toLocaleString('de', {
                            style: 'currency',
                            currency: 'EUR',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}/>
                    </Bar>
                </BarChart>
                <Box marginBottom={'20px'} textAlign={'center'} fontSize={'14px'} fontWeight={'bold'} color={'rgba(0, 0, 0, 0.54)'} style={{ whiteSpace: 'pre-line' }}>
                    {
                        `${t('spgo.dashboard.graphDash.barVolume.title')} (${scope})\n${t('spgo.dashboard.graphDash.upperLowerMessage')}`
                    }
                </Box>
            </Grid>
            { /* Average tip */}
            <Grid item xs={12} md={6} lg={6}>
                <Box style={{ width: '170px', color: '#fff', padding: '85px 0', borderRadius: '50%', background: 'rgb(33, 150, 243)', margin: '10px auto 50px' }}
                     textAlign={'center'} lineHeight={0} fontSize={'40px'} fontWeight={'fontWeightBold'} color={'black'}>
                    {`${avgTip.result}%`}
                </Box>
                <Box textAlign={'center'} fontSize={'14px'} fontWeight={'bold'} color={'rgba(0, 0, 0, 0.54)'}>
                    {`${t('spgo.dashboard.graphDash.pieTip')} (${scope})`}
                </Box>
            </Grid>
            { /* Bar chart with average tip percentages by payment type */}
            <Grid item xs={12} md={6} lg={6}>
                <BarChart width={350} height={230} data={barChartTipData.filter(entry => entry.success)} barCategoryGap={10} maxBarSize={20}
                          style={{ fontSize: '10px', margin: 'auto' }} layout="vertical">
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis type="number" domain={[0, dataMax => (Math.round(dataMax) + 10)]} tickFormatter={tick => tick.toLocaleString('de', { style: 'decimal' })}/>
                    <YAxis type="category" dataKey="name"/>
                    <Tooltip formatter={(value) => `${value.toLocaleString('de', { style: 'decimal' })}%`}/>
                    <Bar name="Trinkgeld" dataKey="avgTip" fill="#82ca9d">
                        {
                            barChartTipData.filter(entry => entry.success).map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color}/>
                            ))
                        }
                        <LabelList position='right' formatter={(value) => `${(Math.round(value * 100) / 100).toLocaleString('de', { style: 'decimal' })}%`}/>
                    </Bar>
                </BarChart>
                <Box textAlign={'center'} fontSize={'14px'} fontWeight={'bold'} color={'rgba(0, 0, 0, 0.54)'}>
                    {`${t('spgo.dashboard.graphDash.barTip')} (${scope})`}
                </Box>
            </Grid>
        </Grid>
    )
}

PaymentStatusDash.propTypes = {
    transaction: PropTypes.array.isRequired,
    gateway: PropTypes.array.isRequired,
}

export default PaymentStatusDash
